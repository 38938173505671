import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';

import { Event, EventType, Job } from '../../../generated/types';
import { Colors } from '../../../styles/colors';

import { Form } from '../../atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { InfoAlert } from '../../atoms/InfoAlert';
import { TextInput } from '../../atoms/Form/TextInput';
import { SelectInput } from '../../atoms/Form/SelectInput/SelectInput';
import { ChevronRightIcon } from '../../atoms/Icons';
import { PlacesAutocompleteInput } from '../../atoms/Form/PlacesAutocompleteInput';
import { Copy } from '../../atoms/Typography';

export const AddEventFromCalendarModal = ({
  event,
  currentDate,
  jobs,
  handleCancel,
  handleSubmit,
}: AddEventFromCalendarModalProps) => {
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    handleSubmit(data, event ? event : undefined);
  };

  const jobsOptions = jobs.map((job) => {
    return {
      value: job.uuid,
      label: `${job.jobTitle} - ${job.company}`,
    };
  });

  const startAt = currentDate ? `${currentDate}T12:00` : '';
  const endAt = currentDate ? `${currentDate}T13:00` : '';

  return (
    <div>
      <pre>{JSON.stringify(endAt, null, 2)}</pre>
      {event ? <H2>Edit Event</H2> : <H2>Add Event</H2>}
      <InfoAlert>
        {event ? (
          <Copy>You can edit the event to your calendar in relation to this Job Opportunity.</Copy>
        ) : (
          <Copy>You can add event to your calendar in relation to a Job Opportunity.</Copy>
        )}
      </InfoAlert>
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <SelectInput name="jobUuid" noMargin={true} defaultValue="" required label="Job Application">
          {jobsOptions.map((job) => (
            <option key={job.value} value={job.value}>
              {job.label}
            </option>
          ))}
        </SelectInput>
        <SelectInput name="type" noMargin={true} defaultValue={event?.type || EventType.Face2Face} label="Type">
          <option value={EventType.Face2Face}>Face to face</option>
          <option value={EventType.PhoneCall}>Phone call</option>
          <option value={EventType.VideoCall}>Video call</option>
          <option value={EventType.Other}>Other</option>
        </SelectInput>
        <TextInput
          name="description"
          fullWidth
          type="text"
          label="Description"
          defaultValue={event?.description || ''}
          noMargin={true}
        />
        <TextInput
          name="startAt"
          fullWidth
          type="datetime-local"
          label="Start"
          required
          defaultValue={startAt}
          InputLabelProps={{ shrink: true }}
          noMargin={true}
        />
        <TextInput
          name="endAt"
          fullWidth
          type="datetime-local"
          label="End"
          required
          defaultValue={endAt}
          InputLabelProps={{ shrink: true }}
          noMargin={true}
        />
        <TextInput
          name="meetingUrl"
          fullWidth
          type="text"
          label="Meeting URL"
          defaultValue={event?.meetingUrl || ''}
          noMargin={true}
        />
        <PlacesAutocompleteInput
          defaultValue={event?.location || ''}
          name="location"
          label="Location"
          placeholder="Where is the event?"
        />

        <WrapperButtons>
          <SecondaryButton inline onClick={handleCancel}>
            Cancel
          </SecondaryButton>
          {event ? (
            <PrimaryButton type="submit" inline iconRight={<ChevronRightIcon color={Colors.White} size={1} />}>
              Update
            </PrimaryButton>
          ) : (
            <PrimaryButton type="submit" inline iconRight={<ChevronRightIcon color={Colors.White} size={1} />}>
              Add
            </PrimaryButton>
          )}
        </WrapperButtons>
      </Form>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  description: Yup.string().max(100, 'Too Long!'),
  startAt: Yup.string().required('The start date is required'),
  endAt: Yup.string().required('The end date is required'),
  type: Yup.string().required('The type of the event is required'),
  jobUuid: Yup.string().required('The job is required'),
});

type FormData = Yup.InferType<typeof validationSchema>;

interface AddEventFromCalendarModalProps {
  jobs: Job[];
  event?: Event | null;
  handleSubmit: (a: any, e?: Event) => void;
  handleCancel: (a: any) => void;
  currentDate?: string;
}
